import React from 'react'

import Layout from '../../components/layout'
import { SmallTitlePage } from '../../components/common/smallTitlePage'
import { BeautyTreatments } from '../../components/common/beautyTreatments'
import { useStaticQuery, graphql } from 'gatsby'
import Seo from '../../components/seo'


const TorunModeling = () => {

    const data = useStaticQuery(graphql`
    {
        allContentfulTorunModelowanieSylwetki(sort: {fields: kolejnosc}) {
          nodes {
            id
            zdjecieGlowne {
                gatsbyImageData(layout: CONSTRAINED)
            }
            nazwaZabiegu
            opisZabiegu {
              opisZabiegu
            }
            slug
          }
        },

        file(relativePath: {eq: "bodymedgroup-bydgoszcz.jpg"}) {
          id
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              
            )
          }
        }
      }
  `)

  const treatments = data.allContentfulTorunModelowanieSylwetki.nodes

    return (
        <Layout torun>
          <Seo
            title="Modelowanie Sylwetki - zabiegi ujędrniające "
            description="Skuteczne zabiegi ujędrniające i wyszczuplające w klinice BodyMed Group Toruń. Endermologia LPG Alliance, Kriolipoliza CoolTech, Karboksyterapia."
            url="https://bodymedgroup.pl/torun-modelowanie-sylwetki"
          />
        <SmallTitlePage 
        title='Modelowanie Sylwetki' 
      />
            <BeautyTreatments treatments={treatments}/>
        </Layout>
    )
}

export default TorunModeling
